<template>
  <div class="dyr-service">
    <Title
      class="dyr-service-title"
      njTitle="top-title"
      typeOf="drying-page"
      :title="this.address + `数字烘干`"
    ></Title>

    <div class="dyr-service-content">
      <div class="dyr-service-left">
        <Power></Power>
        <Video class="video"></Video>
        <Service
          class="dyr-service-service"
          :ronmentData="ronmentData"
        ></Service>
      </div>
      <div class="dyr-service-middle">
        <Mddle :dryingstoreData="dryingstoreData"  @open="onOpen" />
      </div>
      <div class="dyr-service-right">
        <Right />
      </div>
    </div>
    <bottomNav class="middle-bottomNav" />
    <MaskBox :changeMask="showMask" :TanleContent="TanleContent"    @getMask="closeMask">
     
    </MaskBox>
  </div>
</template>

<script>
import Title from "./components/title.vue";
import Power from "./powerr.vue";
import Service from "./servicee.vue";
import Mddle from "./middle/indexx.vue";
import bottomNav from "./components/bottomNavV.vue";
import Right from "./right/indexx.vue";
import Right_fin from "./right/right_finn.vue";
import { getenvironment, dryingstore,selectAllByPage } from "@/api/njApi/honggan.js";
import MaskBox from "@/views/drying/components/MaskTool.vue";
import Video  from './components/video.vue'

export default {
  components: {
    Title,
    Power,
    Service,
    Mddle,
    bottomNav,
    Right,
    Right_fin,
    MaskBox,
    Video
  },
  created() {
    this.getList();
    this.getselectAllByPage()
    this.address = localStorage.getItem("address");
  },
  data() {
    return {
      address: "",
      ronmentData: "",
      dryingstoreData: "",
      tubiaodata: "",
      showMask: false,
      TanleContent:[]
   
    };
  },
  methods: {
    getList() {
      // 烘干中心厂区环境
      getenvironment({
        name: this.address + "厂区",
      })
        .then((res) => {
          this.ronmentData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      dryingstore({
        name: this.address + "烘干",
      })
        .then((res) => {
          this.dryingstoreData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getselectAllByPage(){
      selectAllByPage({
       
      })
        .then((res) => {
       this.TanleContent=res.data.records
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onOpen(){
      this.showMask=true
    },
    closeMask (item) {
      this.showMask = item;
    },
  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  font-size: companyW(18vw);
  box-sizing: border-box;
  color: #fff;
}
.dyr-service {
  position: relative;
  width: companyW(1920vw);
  height: companyH(1080vh);
  box-sizing: border-box;
  background-image: url(../../assets/drying-page/bcg-img.png);
  background-color: rgba(8, 27, 25);
  background-size: cover;
  background-repeat: no-repeat;
  // background-color: #1c3e50;

  .dyr-service-title {
    padding-top: companyH(8vh);
    width: 100%;
    height: companyH(70vh);
    margin: 0 auto;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .dyr-service-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .dyr-service-left {
      width: companyW(482vw);
      height: companyH(978vh);
      margin-left: companyW(20vw);

      .dyr-service-service,.video {
        margin-top: companyH(21vh);
      }
    }

    .dyr-service-right {
      margin-right: companyW(20vw);
      height: companyH(978vh);

      .right_fin {
        margin-top: companyH(21vh);
      }
    }
  }

  .middle-bottomNav {
    position: absolute;
    bottom: 0;
    left: companyW(632vw);
    background-image: url(../../assets/drying-page/bottomNav.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
}
</style>
