<template>
  <div class="dry-service">
    <moduleTitle title="除尘设备状态"></moduleTitle>
    <!-- 整个 -->
    <div class="zongti">
      <div class="dbox" v-for="item in list" :key="item.id">
        <div class="box" :class="getClass(item.dustSystemStatus)"></div>
        <!-- <div class="box box2" :class="getClass1()"></div> -->
        <div class="text">{{ item.dustSystem }}</div>
        <!-- <div class="text cchen">除尘系统2</div> -->
        <div>
          <el-switch class="switchStyle" style="display: block" v-model="item.dustSystemStatus" active-color="#03FFEF "
            inactive-color="#DCDCDC" active-text="开" inactive-text="关">
          </el-switch>
        </div>
        <!-- <div class="switch2">
        <el-switch
          class="switchStyle"
          style="display: block"
          v-model="flag2"
          active-color="#03FFEF "
          inactive-color="#DCDCDC"
          active-text="开"
          inactive-text="关"
        >
        </el-switch>
      </div> -->

      </div>
    </div>

  </div>
</template>

<script>
import moduleTitle from './components/moduleTitle.vue';
import { getProcessEnvironment } from '../../api/processApi/process';

export default {
  components: {
    moduleTitle,
  },
  data() {
    return {
      flag: true,
      flag2: false,
      list: []
    };
  },
  mounted() {
    this.getprocessenvironment()
  },
  methods: {
    getClass(val) {
      if (val === false) {
        return 'img1';
      } else return 'img';
    },
    getprocessenvironment() {
      let name = '中诚加工厂区'
      getProcessEnvironment(name).then(res => {
       
        this.list = res.data.dustList
        this.name = this.list[0].dustSystem
        this.flag = this.list[0].dustSystemStatus
        this.name2 = this.list[1].dustSystem
        this.flag2 = this.list[1].dustSystemStatus
      }).catch(err => {
        console.log(err);
      })
    }
  },
  props: ['ronmentData'],
  watch: {
    ronmentData: {
      handler(newVal) {
        this.list = newVal.dustList;
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.img {
  background-image: url(../../assets//drying-num/色相／饱和度\ 564.png);
}

.img1 {
  background-image: url(../../assets//drying-num/排风机\ 拷贝.png);
}

.dry-service {
  position: relative;
  width: companyW(482vw);
  height: companyH(322vh);
  background: rgba(0,20,36,0.4);
border: 1px solid;
border-image: linear-gradient(0deg, #E69635, #A25B21) 10 10;
  

  .zongti {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: ompanyW(482vw);
    overflow: auto;
    height: companyH(322vh);

  }

  .dbox {
    display: flex;
    z-index: 999;
    flex-direction: column;
    align-items: center;
    // width: companyW(140vw);
    height: companyH(220vh);
    justify-content: space-between;

    .box {
      width: companyW(140vw);
      height: companyH(140vh);
      // margin-top: companyH(63vh);
      // margin-left: companyW(59vw);

      background-repeat: no-repeat;
      background-size: contain;
    }

    .box2 {
      margin-left: companyW(85vw);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .text {
    font-size: companyW(18vw);
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    // margin-top: companyH(19vh);
    // margin-left: companyW(89vw);
  }

  .cchen {
    margin-left: companyW(144vw);
  }

  // .Switch {
  //   // margin-left: companyW(76vw);

  //   // margin-top: companyH(10vh);

  //   display: flex;
  //   z-index: 9999;
  //   .switch2 {
  //     margin-left: companyW(150vw);
  //   }
  // }
}

.dry-service::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 20, 36, 0.4);

  width: 100%;
  height: 100%;
  z-index: -1;
  /* 将伪元素放在父元素底部 */
  // opacity: 0.74;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  width: 5px;

}</style>
