<template>
  <div class="Right">
    <moduleTitle title="烘干塔温度分析"></moduleTitle>
    <div class="upperright">
      <div class="dryingTower">
        <!-- <div class="dryingTowerImg" v-for="(item,index) in tableList" :key="item.id"
        >
          <div class="towerImg">


            <div ref="img" class="img" :class="getClass(item.dryingTowerStatus)"    @click="currentData(item.id, $event, item.dryingTowerName,index)"></div>
          </div>
          <div class="towerName"  :style="{ color: num != index ? '#fff' : '#e69635' }">{{ item.dryingTowerName }}</div>
        </div> -->
        <!-- <div
          v-for="(item, index) in tableList"
          :key="index"
          @click="pointTower(item.dryingTowerName, index)"
          class="bigBox"
        >
          <div>
            <img
              :src="
                tupian != index
                  ? require('../../../assets/drying-num/tower-orange.png')
                  : require('../../../assets/drying-num/tower-orange-point.png')
              "
              :class="tupian != index ? 'img1' : 'img2'"
            />
          </div>
          <div
            :style="{ color: tupian != index ? '#fff' : '#e69635' }"
            class="towerName"
          >
            {{ item.dryingTowerName }}
          </div>
        </div> -->
      </div>
    </div>
    <div class="separation"></div>
    <div class="LineChart">
      <Echart :option="option"></Echart>
    </div>
  </div>
</template>

<script>
import moduleTitle from "../components/moduleTitle.vue";
import Echart from "@/components/echarts/index.vue";
import option from "@/views/drying/config/temperature.js";
import { getallTemperature, gettemperature } from "@/api/njApi/honggan.js";

export default {
  props: ["tubiaodata"],
  components: {
    moduleTitle,
    Echart,
  },
  data() {
    return {
      tupian: "你好word",

      currentData: null,
      option,
      tableList: [],
      showNum: false,
      num: -1,
    };
  },
  watch: {
    tubiaodata: {
      handler(newval) {
        this.option.xAxis[0].data = newval.map((x) => {
          return x.timePoint;
        });
        this.option.series[0].data = newval.map((x) => {
          return x.airInletTemperature;
        });
        this.option.series[1].data = newval.map((x) => {
          return x.airOutletTemperature;
        });
        this.option.series[2].data = newval.map((x) => {
          return x.towerTemperature;
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    getClass(flag) {
      if (flag) {
        return "bgc";
      } else {
        return "bgc1";
      }
    },
    // 因为是子组件所以要请求接口拿到烘干塔名字用名字来请求图表接口
    getList() {
      getallTemperature({})
        .then((res) => {
          this.tableList = res.data;
          var id = res.data[0].id;
          // 以第一个烘干塔名字请求首次图表数据
          gettemperature({
            id: id,
          })
            .then((res) => {
              this.option.xAxis[0].data = res.data.map((x) => {
                return x.timePoint;
              });
              this.option.series[0].data = res.data.map((x) => {
                return x.airInletTemperature;
              });
              this.option.series[1].data = res.data.map((x) => {
                return x.airOutletTemperature;
              });
              this.option.series[2].data = res.data.map((x) => {
                return x.towerTemperature;
              });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.img {
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
}

.bgc {
  background-image: url("../../../assets/drying-num/tower-orange.png");
  width: companyW(16vw);
  height: companyH(39vh);
}

.bgc1 {
  background-image: url("../../../assets/drying-num/tower-whtie.png");
  width: companyW(16vw);
  height: companyH(39vh);
}

.active {
  background-image: url("../../../assets/drying-num/tower-orange-point.png");
  width: companyW(44vw);
  height: companyH(48vh);
  margin-left: companyW(-14vw);
}

.active1 {
  background-image: url("../../../assets//drying-num/tower-white-point.png");
  width: companyW(44vw);
  height: companyH(48vh);
  margin-left: companyW(-14vw);
}

.Right {
  // margin-top:companyH(21vh) ;
  box-sizing: border-box;
  height: 100%;
  background-color: black;
  position: relative;
  // top: 10vh;
  width: companyW(482vw);
  height: companyH(470vh);
  background: rgba(0, 20, 36, 0.4);
  border: companyW(1vw) solid;
  border-image: linear-gradient(0deg, #e69635, #21261a) 10 10;
  // opacity: 0.74;
  // padding-left: companyW(17vw);
  padding-top: companyH(28vw);

  overflow: auto;
  overflow-x: hidden;
  overflow-y: hidden;

  .upperright {
    .dryingTower {
      display: flex;
      // justify-content: space-evenly;
      align-items: center;
      width: companyW(482vw);
      height: companyH(150vh);
      overflow-x: auto;
      margin-top: companyH(-40vh);

      .dryingTowerImg {
        flex-shrink: 0;
        width: companyW(65vw);
        height: companyH(65vh);
        margin-left: companyH(10vh);

        .towerImg {
          margin: 0 auto;
          width: companyW(16vw);
          height: companyH(39vh);
        }

        .towerName {
          margin-bottom: 0;
          text-align: center;
          font-size: companyW(16vw);
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          margin-top: companyH(20vh);
        }
      }
    }
  }

  .separation {
    width: companyW(438vw);
    height: companyH(4vh);
    background-image: url(../../../assets/drying-num/形状\ 582\ 拷贝\ 3.png);
    margin-left: companyW(22vw);
  }

  .LineChart {
    width: companyW(440vw);
    height: companyH(396vh);
    position: absolute;
    top: companyH(50vh);
    left: companyW(20vw);
  }
}
::-webkit-scrollbar {
  // 纵向滚动条和横向滚动条宽度
  width: companyW(10vw);
  height: companyH(10vh);
}
::-webkit-scrollbar-thumb {
  // 滚动条背景条样式
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #fff;
}
::-webkit-scrollbar-track {
  // 滚动条按钮样式
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  background: #5b5f63;
}
.bigBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  width: companyW(80vw);

  .img1 {
    width: companyW(16vw);
    height: companyH(39vh);
  }
  .img2 {
    width: companyW(52vw);
    height: companyH(56vh);
  }
  .towerName {
    font-size: companyW(16vw);
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
    margin-top: companyH(20vh);
    width: companyW(80vw);
    text-align: center;
  }
}
</style>
