<template>
  <div class="bottomNav">
    <div
      :class="{ 'bottomNav-Highlight-left': index === 1 }"
      @click="selectHighlight(1)"
      class="left-common-style"
    >
      <span
class="bottomNav-Highlight-item bottomNav-Highlight-desc"
        >烘干服务</span
      >
    </div>
    <div
      :class="{ 'bottomNav-Highlight-right': index === 2 }"
      @click="selectHighlight(2)"
      class="right-common-style"
    >
      <span
class="bottomNav-Highlight-item bottomNav-Highlight-desc"
        >数字管控</span
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 2,
    };
  },
  methods: {
    selectHighlight(value) {
      console.log(value, 'selectHighlight');
      this.index = value;
      console.log(this.$route.path);

      if(this.index===1){
        console.log(value);
        if (this.$route.path !== '/drying-service') {

          this.$router.push({
            path: '/drying-service',
          });
        }
      }else{
        console.log(value,2);
        if (this.$route.path !== '/drying-Num') {


          this.$router.push({
            path: '/drying-Num',
          });
        }
      }
    },
  },

};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
@font-face {
  font-family: Source Han Sans CN;
  src: url(../../../assets/font/np4SotSdlbui.woff);
}
.bottomNav {
  width: companyW(600vw);
  height: companyH(62vh);
  position: relative;
  .left-common-style {
    position: absolute;
    left: companyW(90vw);
    top: companyH(-13vh);
    width: companyW(198vw);
    height: companyH(60vh);
    .bottomNav-Highlight-item {
      width: companyW(72vw);
      height: companyH(18vh);
      display: block;
      margin-top: companyH(15vw);
      margin-left: companyW(105vw);
      font-size: companyH(16vh);
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    }
  }
  .right-common-style {
    position: absolute;
    right: companyW(130vw);
    top: companyH(-13vh);
    width: companyW(198vw);
    height: companyH(60vh);
    .bottomNav-Highlight-item {
      width: companyW(72vw);
      height: companyH(18vh);
      display: block;
      margin-top: companyH(15vw);
      margin-left: companyW(105vw);
      font-size: companyH(16vh);
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    }
  }
  .bottomNav-Highlight-left {
    position: absolute;
    left: companyW(90vw);
    top: companyH(-13vh);
    width: companyW(198vw);
    height: companyH(60vh);
    background-image: url(../../../assets/drying-page/Highlight.png);
    background-size: cover;
    .bottomNav-Highlight-item {
      width: companyW(72vw);
      height: companyH(18vh);
      display: block;
      margin-top: companyH(15vw);
      margin-left: companyW(105vw);
      font-size: companyH(16vh);
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    }
  }
  .bottomNav-Highlight-right {
    position: absolute;
    right: companyW(130vw);
    top: companyH(-13vh);
    width: companyW(198vw);
    height: companyH(60vh);
    background-image: url(../../../assets/drying-page/Highlight.png);
    background-size: cover;
    .bottomNav-Highlight-item {
      width: companyW(72vw);
      height: companyH(18vh);
      display: block;
      margin-top: companyH(15vw);
      margin-left: companyW(105vw);
      font-size: companyH(16vh);
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    }
  }
  // .bottomNav-Highlight-left,.bottomNav-Highlight-right{
  //   position: absolute;
  //   right: companyW(130vw);
  //   top: companyH(-13vh);
  //   width: companyW(198vw);
  //   height: companyH(60vh);
  //   .bottomNav-Highlight-item{
  //     width: companyW(72vw);
  //     height: companyH(18vh);
  //     display: block;
  //     margin-top: companyH(15vw);
  //     margin-left: companyW(105vw);
  //     font-size: companyH(16vh);
  //     font-family: Source Han Sans CN;
  //     font-weight: bold;
  //     color: #FFFFFF;
  //     text-shadow: 0px 2px 4px rgba(0,0,0,0.4);
  //   }
  // }
}
</style>
