<template>
  <div class="middle-navList">
    <div
      class="middle-navList-item"
      v-for="(item, index) in navList"
      :key="index"
    >
      <span class="navList-item-title">{{ item.name }}</span>
      <div
        class="navList-item-desc"
        :style="{ backgroundImage: `url(${item.bgcTitle})` }"
      >
        {{ item.number }}个
      </div>
    </div>
  </div>
</template>

<script>
import { getStatusNum } from "@/api/njApi/honggan.js";

export default {
  // props: {
  //   Option: {
  //     type: Array,
  //     require: true,
  //     default: () => {},
  //   },
  // },
  // watch: {
  //   Option: {
  //     deep: true,
  //     handler(newValue) {
  //       this.navList = newValue;
  //     },
  //   },
  // },
  data() {
    return {
      navList: [
        {
          name: "烘干塔",
          number: "",
          bgcTitle: require("../../../assets/drying-page/nav_bgc.png"),
        },
        {
          name: "工作中",
          number: "",
          bgcTitle: require("../../../assets/drying-page/nav_bgc.png"),
        },
        {
          name: "空闲中",
          number: "",
          bgcTitle: require("../../../assets/drying-page/nav_bgc.png"),
        },
        {
          name: "检修中",
          number: "",
          bgcTitle: require("../../../assets/drying-page/nav_bgc.png"),
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      console.log(66);
      getStatusNum({})
        .then((res) => {
          console.log(555);
          this.navList[0].number = res.data.count1;
          this.navList[1].number = res.data.count2;
          this.navList[2].number = res.data.count3;
          this.navList[3].number = res.data.count4;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

@font-face {
  font-family: Source Han Sans CN;
  src: url(../../../assets/font/np4SotSdlbui.woff);
}

.middle-navList {
  width: 100%;
  height: 100%;
  display: flex;
  // justify-content: space-evenly;
  justify-content: space-between;
  align-items: center;

  .middle-navList-item {
    width: companyW(208vw);
    height: 100%;
    position: relative;
    // margin-right: companyH(10vh);

    .navList-item-title {
      position: absolute;
      top: companyH(-7vh);
      left: 50%;
      transform: translate(-50%);
      height: companyH(16vh);
      font-size: companyW(18vw);
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      line-height: companyH(16vh);
    }

    .navList-item-desc {
      width: 100%;
      text-align: center;
      height: companyH(65vh);
      // background-image: url(../../../assets//drying-page/nav_bgc.png);
      background-repeat: no-repeat;
      background-size: contain;
      font-size: companyH(26vh);
      font-family: Source Han Sans SC;
      font-weight: 500;
      color: #ffffff;
      line-height: companyH(58vh);
  
    }
  }
}
</style>
