import echarts from "echarts";

export var data = [
  {  sub_title: "21", value: 12, min: 0, max: 500 },
];

// 不同数据长度，圆心位置
var pos_cfg = {
  1: [["50%", "50%"]],
};
var data_len = data.length;

// 获取位置信息
var pos_info = pos_cfg[data_len];

// 圆环颜色配置
var color_cfg = [
  [
    { offset: 0, color: "rgba(243, 146, 82, 1)" },
    { offset: 0.5, color: "rgba(255, 163, 90, 1)" },
    { offset: 1, color: "rgba(235, 87, 95, 1)" },
  ],
];

// 渲染数据
var series = [],
  item = null;

for (var i in data) {
  item = data[i];

  // 处理最大值及最小值
  if (!item.min) item.min = 0;
  if (!item.max)
    item.max =
      item.value / 0.8 + Math.random(0, parseInt(item.value * 0.2)) + 1;

  // 获取比率
  item.rate = Math.round((item.value / item.max) * 10000) / 100;

  // 如果最大值大于100，则转换为百分比
  // if (item.max > 100) {
  //   item.max = 100;
  //   item.min = 0;
  // }

  // 拼接图表参数
  series.push(
    {
      name: "最外层",
      type: "gauge",
      center: pos_info[i],
      radius: "98%",
      startAngle: 150,
      endAngle: -209.999,
      axisLine: {
        show: true,
        lineStyle: { width: 2, color: [[1, "rgba(244, 156, 48,1)"]] },
      },
      axisLabel: { show: false },
      axisTick: { show: false },
      splitLine: { show: false },
      detail: { show: false },
      pointer: { show: false },
    },
    {
      name: "内层渐变区",
      type: "gauge",
      radius: "50%",
      splitNumber: 10, // 刻度数量
      center: pos_info[i],
      startAngle: 150,
      endAngle: -209.999,
      axisLine: {
        lineStyle: {
          color: [
            [
              1,
              {
                type: "radial",
                colorStops: [
                  { offset: 0.72, color: "#322413" },
                  { offset: 0.84, color: "#422b11" },
                  { offset: 0.98, color: "#5e3a0f" },
                  { offset: 1, color: "#8e5511" },
                ],
              },
            ],
          ],
          width: 1000,
        },
      },
      splitLine: { show: false }, // 分隔线
      axisTick: { show: false }, // 刻度线
      axisLabel: { show: false }, // 刻度标签
      pointer: { show: false }, // 仪表盘指针
      detail: { show: false },
    },
    {
      name: "中间层",
      type: "gauge",
      center: pos_info[i],
      radius: "84%",
      min: item.min, // 最小刻度
      max: item.max, // 最大刻度
      splitNumber: 10, // 刻度数量
      startAngle: 245,
      endAngle: -65,
      data: [{ value: item.rate }],

      axisLine: {
        show: true,
        lineStyle: {
          width: 10,
          color: [
            [
              item.rate / 100,
              new echarts.graphic.LinearGradient(0, 1, 1, 0, color_cfg[i]),
            ],
            [1, "rgba(69, 50, 28,.1)"],
          ],
        },
      },
      axisLabel: {
        show: true,
        color: "#fff",
        distance: 25,
        textStyle: { fontSize: 10 },
      },
      axisTick: {
        show: true,
        length: -5,
        distance: -10,
        lineStyle: { color: "rgba(246, 171, 80, 1)" },
      },
      splitLine: {
        show: true,
        length: -10,
        distance: -10,
        lineStyle: { width: 1, color: "rgba(246, 171, 80, 1)" },
      },
      detail: {
        offsetCenter: [0, "-5%"],
        textStyle: { fontSize: 20, color: "#fff" },
        formatter: [item.title, `{name|${item.sub_title}}`].join("\n"),
        rich: {
          name: {
            fontSize: 22,
            lineHeight: 35,
            color: "#fff",
            fontWeight: "600",
          },
          b: {
            fontSize: 16,
            padding: [0, 0, 0, 0],
            fontFamily: "Microsoft YaHei",
            fontweight: "400",
            lineHeight: 16,
            color: "#FFFFFF",
          },
        },
      },
      title: { color: "#fff" },
      pointer: { show: false },
    }
  );
}
var option = {
  backgroundColor: "transparent",
  series: series,
};

export default option;
