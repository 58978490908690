<template>
  <!-- 标题组件 -->
  <div class="module-title">
    <div class="module-title-desc">
      {{ title }}
    </div>
    <!-- 选项列表 -->
    <el-select v-model="currentTime" @change="handChange">
      <el-option
        v-for="(item, index) in optionsData"
        :key="index"
        :label="item.cameraName"
        :value="item.channelNo"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      optionsData: [],
      currentTime: '',
      AllDatas: [],
    };
  },

  created() {},
  watch: {
    TimeOptions: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        this.optionsData = newVal;
        this.currentTime = this.optionsData[0].cameraName;
      },
    },
    allData: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        this.AllDatas = newVal;
      },
    },
  },
  mounted() {},
  methods: {
    handChange(val) {

      const loginVo = this.AllDatas.find(obj => obj.channelNo === val);

      this.$emit('getdarkroomId', loginVo);
    },
  },
};
</script>

  <style  lang="scss" >
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

@font-face {
  font-family: Source Han Sans CN;
  src: url(../../../assets/font/np4SotSdlbui.woff);
}

.module-title {
  position: absolute;
  top: companyH(-38vh);
  left: companyW(-19vw);
  width: companyW(500vw);
  height: companyH(109vh);
  background-image: url(../../../assets/drying-page/module_title.png);
  background-size: cover;

  .module-title-desc {
    position: absolute;
    left: companyW(53vw);
    bottom: companyH(50vh);
    width: companyW(120vw);
    height: companyH(18vh);
    font-size: companyH(18vh);
    font-family: Source Han Sans CN;
    font-weight: 700;
    font-style: italic;
    color: #ffffff;
    line-height: companyH(22vh);
    text-shadow: 0px 0px 9px rgba(32, 22, 0, 0.45);
  }

  .el-select {
    position: absolute;
    right: 0;
    top: companyH(38vh);
    background: #1e2801;
    // opacity: 0.8;
    z-index: 3;

    .el-input__inner {
      width: companyW(160vw);
      height: companyH(30vh);
      background: #1e2801;
      border-radius: 0;
      border: #c4792b companyW(1vw) solid;
      font-size: companyW(18vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #c3e1ff;
    }

    .el-input__icon {
      line-height: companyH(30vh);
    }
  }
}
</style>
