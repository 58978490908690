<template>
  <div>
    <el-dialog
      :visible.sync="chaMask"
      :show-close="false"
      @close="closeMask"
      :destroy-on-close="true"
      v-if="chaMask"
    >
      <dv-border-box-1 :color="['#483417', '#684519']">
        <div class="ToolBox">
          <div class="close" @click="closeMask"></div>
        </div>
        <div class="leftDia">
          <el-table
            stripe
            v-loading="tabLoading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :data="TanleContent"
            style="width: 95%; margin: auto"
            height="500"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="60"
            >
            </el-table-column>
            <el-table-column prop="picture" label="图片" width="120">
              <template v-slot="{ row }">
                <el-image
                  style="width: 80px; height: 80px"
                  :src="row.picture"
                  fit="contain"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column
              prop="carBrand"
              label="品牌"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="model"
              label="型号"
              width="120 "
              align="center"
            >
            </el-table-column>

            <el-table-column prop="carOwner" label="所有人" align="center">
            </el-table-column>
            <el-table-column
              prop="buyDate"
              label="购机时间"
              width="140"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="status"
              label="当前状态"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="carPower"
              label="农机动力"
              align="center"
              width="100"
            >
              <template v-slot="{ row }">
                <span>{{ row.carPower }}Kw</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="price"
              label="农机原值"
              align="center"
              width="140"
            >
              <template v-slot="{ row }">
                <span>{{ row.price }}元</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="allowance"
              label="购机补贴"
              align="center"
              width="100"
            >
              <template v-slot="{ row }">
                <span>{{ row.allowance }}元</span>
              </template>
            </el-table-column>

            <div class="closeBtn"></div>
          </el-table>
        </div>
      </dv-border-box-1>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    changeMask: {
      type: Boolean,
      default: false,
    },

    NavList: {
      type: Array,
      default: () => [],
    },
    TanleContent: {
      type: Array,
      default: () => [],
    },
    currentId: {
      type: Number,
    },
    uid: {
      type: Number,
    },
  },
  data() {
    return {
      tabLoading: true,
      currentInd: 0,
      // ...options,
      chaMask: false,
      rateColor: ["#009ad6"],
    };
  },
  watch: {
    changeMask: {
      handler(newv, oldv) {
        this.chaMask = newv;
      },
    },
  },

  methods: {
    closeMask() {
      this.currentInd = 0;
      this.chaMask = false;

      this.$emit("getMask", false);
    },
  },

  created() {},
  updated() {},
  mounted() {
    setTimeout(() => {
      this.tabLoading = false;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  font-size: companyW(20vw);
  box-sizing: border-box;
}
::v-deep .el-dialog {
  width: companyW(1700vw);
  background: #000000;
  // opacity: 0.9;
  background: rgba($color: #000000, $alpha: 0.9);
}
::v-deep .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-table th.el-table__cell > .cell {
  color: #fff;
  font-size: companyW(18vw);
}
.ToolBox {
  width: 95%;

  // margin: 0 auto;
  padding: companyH(88vh) 0;
  display: flex;
  .leftDia {
    width: 55%;
  }
  .leftDia1 {
    width: 50%;
  }
  .close {
    width: companyW(20vw);
    height: companyW(20vw);
    background: url("../../../assets/njImgs/closeDia.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: companyH(25vh);
    right: companyW(35vw);
  }
  .NavList {
    display: flex;
    flex-direction: column;
    margin-right: companyW(30vw);
    .NavOne {
      background-image: url(../../../assets/njImgs/toolNav.png);
    }
    .NavOne1 {
      background-image: url(../../../assets/njImgs/toolNav_orange.png);
    }
    .NavOne2 {
      background-image: url(../../../assets/njImgs/toolNav_white.png);
    }
    .NavOne3 {
      background-image: url(../../../assets/njImgs/toolNav_green.png);
    }
    .NavOne4 {
      background-image: url(../../../assets/njImgs/toolNav_blue.png);
    }
    .NavOne5 {
      background-image: url(../../../assets/njImgs/toolNav_puple.png);
    }

    .NavOne7 {
      background-image: url(../../../assets/njImgs/toolNav.png);

      width: companyW(220vw);
      height: companyH(50vh);

      background-repeat: no-repeat;

      background-size: cover;
      margin-bottom: companyH(15vh);

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: companyH(4vh) companyW(10vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      opacity: 0.5;
      color: #33f8f8;
      cursor: pointer;
      .NavTitle {
        margin-left: companyW(10vw);
        font-size: companyH(20vh);
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-style: italic;
        color: #fff;
        .NavSpan {
          font-size: companyH(16vh);
          margin-left: companyW(5vw);
          color: #33f8f8;
        }
      }
      .NavTitle2 {
        font-size: companyH(14vh);
        margin-left: companyW(5vw);
        color: #fff;
      }
    }
    .NavTwo {
      background-image: url(../../../assets/njImgs/notFouce.png);
    }
    .NavTwo1 {
      background-image: url(../../../assets/njImgs/notFouce_orange.png);
    }
    .NavTwo2 {
      background-image: url(../../../assets/njImgs/notFouce_white.png);
    }
    .NavTwo3 {
      background-image: url(../../../assets/njImgs/notFouce_green.png);
    }
    .NavTwo4 {
      background-image: url(../../../assets/njImgs/notFouce_blue.png);
    }
    .NavTwo5 {
      background-image: url(../../../assets/njImgs/notFouce_puple.png);
    }
    .NavTwo7 {
      background-image: url(../../../assets/njImgs/notFouce.png);

      width: companyW(220vw);
      height: companyH(50vh);

      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: companyH(15vh);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: companyH(4vh) companyW(10vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;

      color: #33f8f8;
      cursor: pointer;
      .NavTitle {
        margin-left: companyW(10vw);
        font-size: companyH(20vh);
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-style: italic;
        color: #fff;
        .NavSpan {
          font-size: companyH(16vh);
          margin-left: companyW(5vw);
          color: #33f8f8;
        }
      }
      .NavTitle2 {
        font-size: companyH(14vh);
        margin-left: companyW(5vw);
        color: #fff;
      }
    }
    .NavOne,
    .NavOne1,
    .NavOne2,
    .NavOne3,
    .NavOne4,
    .NavOne5,
    .NavTwo,
    .NavTwo1,
    .NavTwo2,
    .NavTwo3,
    .NavTwo4,
    .NavTwo5 {
      width: companyW(200vw);
      height: companyH(50vh);

      background-repeat: no-repeat;

      background-size: cover;
      margin-bottom: companyH(15vh);

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: companyH(4vh) companyW(10vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #33f8f8;
      cursor: pointer;
      .NavTitle {
        margin-left: companyW(10vw);
        font-size: companyW(20vw);
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-style: italic;
        color: #fff;
        .NavSpan {
          font-size: companyH(16vh);
          margin-left: companyW(5vw);
          color: #33f8f8;
        }
      }
      .NavTitle2 {
        font-size: companyH(14vh);
        margin-left: companyW(5vw);
        color: #fff;
      }
    }
  }
}
.NavTwo:hover {
  background-image: url("../../../assets/njImgs/xuanfu.png") !important;
}
.NavTwo1:hover {
  background-image: url("../../../assets/njImgs/xuanfu_orange.png") !important;
}
.NavTwo2:hover {
  background-image: url("../../../assets/njImgs/xuanfu_white.png") !important;
}
.NavTwo3:hover {
  background-image: url("../../../assets/njImgs/xuanfu_green.png") !important;
}
.NavTwo4:hover {
  background-image: url("../../../assets/njImgs/xuanfu_blue.png") !important;
}
.NavTwo5:hover {
  background-image: url("../../../assets/njImgs/xuanfu_puple.png") !important;
}
.NavTwo7:hover {
  background-image: url("../../../assets/njImgs/xuanfu.png") !important;
}
.currentNav {
  opacity: 1 !important;
}

::v-deep .el-table th {
  background: #1b4069;
  border-top: companyW(1vw) solid #1b4069;
  border-bottom: companyW(1vw) solid #1b4069;
  height: companyH(35vh);
  padding: 0;
  text-align: center;
}
::v-deep .cell {
  font-size: companyH(18vh);
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #fff;
}
::v-deep .el-table,
.el-table__expanded-cell {
  background-color: rgba(255, 255, 255, 0);
}
::v-deep .el-table td,
.building-top .el-table th.is-leaf {
  border-bottom: 1px solid rgba(238, 238, 238, 0);
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #71cef916;
  // opacity: 0.1;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-table tr,
::v-deep .el-table::before,
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: #000000;
}
.toolStyle {
  color: "#33f8f8" !important;
  font-size: companyH(14vh);
  text-decoration: underline;
  cursor: pointer;
}
.flexJt {
  display: flex;
  justify-content: space-between;
}
.littleJt {
  width: companyW(11vw);
  height: companyH(26vh);
  background-image: url(../../../assets/njImgs/dialogImg.png);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 999;
  margin-top: companyH(5vh);
  margin-left: companyW(7vw);
}
</style>
