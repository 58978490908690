<template>
  <div class="dry-power">
    <moduleTitle title="厂区环境监测"></moduleTitle>
    <!-- 上 -->
    <div style="display: flex">
      <div class="left">
        <div class="temperature">
          <div class="imgBox"></div>
          <div class="txt">
            <div>{{ ronmentData.airTemperature }}°C</div>
            <span>空气温度</span>
          </div>
        </div>
        <div class="temperature humidness">
          <div class="imgBox2">
            <img src="../../assets/drying-num/组 24252.png" alt="" />
          </div>
          <div class="txt">
            <div>{{ ronmentData.airDampness }}%</div>
            <span>空气湿度</span>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- <Echart :option="factory"></Echart> -->
        <Echart :option="option"></Echart>
        <div class="fenchen">粉尘浓度</div>
      </div>
    </div>
    <img src="../../assets/drying-page/xuxian.png" class="xuxian" />
    <!-- <div id="assessment" class="tubiao"></div> -->
  </div>
</template>

<script>
import moduleTitle from "./components/moduleTitle.vue";
import Echart from "@/components/echarts/index.vue";

import option from "@/views/drying/config/factory.js";
import { getenvironment, getEnvironmentDetail } from "@/api/njApi/honggan.js";
import { data } from "@/views/drying/config/factory.js";
export default {
  components: {
    moduleTitle,
    Echart,
  },
  data() {
    return {
      data,
      option,
      ronmentData: "",
      data1: [],
      data2: [],
      timedata: [],
    };
  },
  mounted() {
    this.getList();
    this.tubiaodata();
  },
  methods: {
    getList() {
      let address = localStorage.getItem('address')
      // 烘干中心厂区环境
      getenvironment({
        name: address+"厂区",
      })
        .then((res) => {
          this.ronmentData = res.data;
          this.option.series[2].detail.formatter = [
            `{name|${res.data.dustLoading}}`,
            `{b|mg/m³}`,
          ].join("\n");
          this.data[0] = {
            title: "粉尘浓度",
            sub_title: `${res.data.dustLoading}`,
            value: res.data.dustLoading,
            min: 0,
            max: 50,
          };
          console.log(this.data[0], 111111);
          处理最大值及最小值;
          if (!this.data[0].min) this.data[0].min = 0;
          if (!this.data[0].max)
            this.data[0].max =
              this.data[0].value / 0.8 +
              Math.random(0, parseInt(this.data[0].value * 0.2)) +
              1;

          // 获取比率
          let rate =
            Math.round((this.data[0].value / this.data[0].max) * 10000) / 100;
          console.log(rate);
          // 如果最大值大于100，则转换为百分比
          if (this.data[0].max > 100) {
            this.data[0].max = 100;
            this.data[0].min = 0;
          }
          this.option.series[2].axisLine = {
            show: true,
            lineStyle: {
              width: 10,
              color: [
                [rate / 100, "rgba(255, 163, 90, 1)"],
                [1, "rgba(69, 50, 28,.1)"],
              ],
            },
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    tubiaodata() {
      getEnvironmentDetail({})
        .then((res) => {
          this.data1 = res.data.map((item) => {
            return item.airDampness;
          });
          this.data2 = res.data.map((item) => {
            return item.airTemperature;
          });
          this.timedata = res.data.map((item) => {
            return item.timePoint;
          });
          this.gettubiao();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gettubiao() {
      const myChart = this.$echarts.init(document.getElementById("assessment"));
      myChart.setOption({
        xAxis: {
          type: "category",
          data: this.timedata,

          splitLine: {
            show: false,
          },
          axisLabel: {
            lineStyle: {
              color: "#BAC6D0", //x轴的颜色
              width: 8, //轴线的宽度
            },
            //修改坐标系字体颜色
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        color: ["#FF6535", "#3DC4F6"],
        legend: {
          data: ["空气温度", "空气湿度"],
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "9%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
          // show: false,
          // axisLine: {
          //   show: false,
          // },
          // axisTick: {
          //   show: false,
          // },
          axisLabel: {
          //修改坐标系字体颜色
          show: true,
          textStyle: {
            color: "#fff",
          },
        },
        },
        series: [
          {
            name: "空气温度",
            data: this.data1,
            type: "line",
            lineStyle: {
              color: "#FF6535",
            },
          },
          {
            name: "空气湿度",
            data: this.data2,
            type: "line",
            lineStyle: {
              color: "#3DC4F6",
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-power {
  position: relative;
  width: companyW(482vw);
  height: companyH(280vh);
  background: rgba(0, 20, 36, 0.4);
  border: 1px solid;
  border-image: linear-gradient(0deg, #e69635, #a25b21) 10 10;

  .left {
    .temperature {
      display: flex;
      width: companyW(206vw);
      height: companyH(93vh);
      border-radius: companyW(5vw);
      border: companyW(1vw) solid #e69635;
      margin-top: companyH(43vh);
      margin-left: companyW(25vw);
      background: transparent;
      border-radius: companyW(10vw);

      .imgBox {
        margin-top: companyH(10vh);
        margin-left: companyW(10vw);
        width: companyW(70vw);
        height: companyH(73vh);
        background-image: url(../../assets/drying-num/组\ 2425.png);
        background-repeat: no-repeat;
        background-size: contain;
      }

      .txt {
        margin-top: companyH(26vh);
        margin-left: companyW(29vw);
        width: companyW(66vw);
        height: companyH(42vh);

        div {
          font-size: companyW(24vw);
          font-family: Source Han Sans SC;
          font-weight: 500;
          color: #e69635;
        }

        span {
          margin-top: companyH(10vh);

          font-size: companyW(14vw);
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }

    .humidness {
      margin-top: companyH(18vh);

      .imgBox2 {
        margin-top: companyH(10vh);
        margin-left: companyW(10vw);
        width: companyW(70vw);
        height: companyH(73vh);

        img {
          width: 100%;
          height: 100%;
          background-size: contain;
        }
      }
    }
  }

  .right {
    width: companyW(203vw);
    height: companyH(203vh);

    margin-top: companyH(43vh);
    margin-left: companyW(26vw);
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  }
}

.fenchen {
  color: #fff;
  font-size: companyW(22vw);
  margin-top: 5px;
  font-weight: bold;
}
.xuxian {
  width: companyW(438vw);
  height: companyH(2vh);
}
.tubiao {
  width:90%;
  height: companyH(350vh);
  margin: 0 auto;
  margin-top: companyH(20vh);
}
</style>
